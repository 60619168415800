import React, { useEffect, useState } from "react";
import Post from "../components/Post";
import ReportForm from "../components/ReportForm";
import EmailAuth from "../components/EmailAuth";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import lottie from "lottie-web";
// import Cookies from "js-cookie";
import lightninganimation from "../components/lottie/lightning.json";
import BleepSpinnerDark from "../components/lottie/BleepSpinnerDark.json";
import InfiniteScroll from "react-infinite-scroll-component";

import { Helmet } from "react-helmet-async";

const Home = ({ domain, userData, refreshUserData, signOut }) => {
  const [userDataState, setUserDataState] = useState(userData);
  const [showFloatingReportButton, setShowFloatingReportButton] =
    useState(false);

  useEffect(() => {
    upadteDownloadTrackerCount();
    const handleScroll = () => {
      // Calculate the scroll position
      const scrollY = window.scrollY || window.pageYOffset;

      // Toggle the visibility of the button based on the scroll position
      if (scrollY > 200) {
        // Adjust the value (100 in this case) to determine when to show the button
        setShowFloatingReportButton(true);
      } else {
        setShowFloatingReportButton(false);
      }
    };

    // Add scroll event listener when component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setUserDataState(userData);
  }, [userData]);

  const [
    userId,
    userType,
    userName,
    userEmail,
    userPassword,
    userOrg,
    userPermissions,
    userAnonymous,
  ] = userDataState;

  const [posts, setPosts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const bleeploadinganimation = React.useRef(null);
  useEffect(() => {
    if (
      bleeploadinganimation.current &&
      !bleeploadinganimation.current.animationLoaded
    ) {
      bleeploadinganimation.current.animationLoaded = true; // Mark animation as loaded to prevent re-render
      lottie.loadAnimation({
        container: bleeploadinganimation.current,
        renderer: "canvas",
        loop: true,
        autoplay: true,
        animationData: BleepSpinnerDark,
      });
    }
  }, [isLoading]);

  const lightninganimationcontainer = React.useRef(null);
  useEffect(() => {
    if (
      lightninganimationcontainer.current &&
      !lightninganimationcontainer.current.animationLoaded
    ) {
      lightninganimationcontainer.current.animationLoaded = true; // Mark animation as loaded to prevent re-render
      lottie.loadAnimation({
        container: lightninganimationcontainer.current,
        renderer: "canvas",
        loop: true,
        autoplay: true,
        // speed: 0.2,
        animationData: lightninganimation,
      });
      lottie.setSpeed(0.6);
    }
  }, []);

  const fetchPosts = () => {
    let url = `/api/posts?page=${page}&size=4`;
    if (userId) {
      url += `?user=${userId}`;
    }

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setPosts((prevPosts) => [...prevPosts, ...data]);
        data.length > 0 ? setHasMore(true) : setHasMore(false);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching posts:", error);
        setIsLoading(false);
      });
    setPage(page + 1);
  };

  // Fetch posts on component mount
  useEffect(() => {
    // setIsLoading(true);
    fetchPosts();
  }, [userId]);

  // Fetch posts after form submission
  const handleReportFormSubmit = () => {
    fetchPosts();
  };

  const [showReportFormModal, setShowReportFormModal] = useState(false);
  const handleCloseReportFormModal = () => {
    setShowReportFormModal(false);
    setReportFormInProgress(false);
  };
  const handleShowReportFormModal = () => {
    setShowReportFormModal(true);
    setReportFormInProgress(true);
  };

  const [showEmailAuthModal, setShowEmailAuthModal] = useState(false);
  const handleCloseEmailAuthModal = () => {
    setShowEmailAuthModal(false);
    if (reportFormInProgress) {
      setShowReportFormModal(true);
    }
  };
  const handleShowEmailAuthModal = () => setShowEmailAuthModal(true);

  const [reportFormInProgress, setReportFormInProgress] = useState(false);

  const handleSaveAndShowEmailAuthModal = () => {
    setReportFormInProgress(true);
    setShowReportFormModal(false);
    setShowEmailAuthModal(true);
  };

  const handleAuthComplete = () => {
    handleCloseEmailAuthModal();
    refreshUserData();
  };

  const trackEvent = (action) => {
    fetch("/api/trackers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: userId,
        action: action,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Event tracked:", data);
      })
      .catch((error) => {
        console.error("Error tracking event:", error);
      });
  };

  const [downloadTrackerCount, setDownloadTrackerCount] = useState(0);

  const upadteDownloadTrackerCount = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch("/api/trackers/count/download,apk", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setDownloadTrackerCount(result.count);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <Helmet>
        <title>BLEEP - Suspect a counterfeit? Report Now!</title>
        <meta
          name="description"
          content="Introducing BLEEP, the revolutionary crowdsourcing app designed to combat counterfeit products and services. Empowering users to report and verify the authenticity of goods, BLEEP connects you directly with brands and enforcement agents."
        />
      </Helmet>

      <div
        style={{
          paddingTop: "40px",
          paddingBottom: "180px",
          background:
            "linear-gradient(90deg, rgba(255, 64, 0, 0.914) 0%, rgb(255, 153, 1) 100%)",
        }}
      >
        <div
          className="bg-pattern"
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100vh",
          }}
        ></div>
        <div
          className="row d-flex align-items-center"
          style={{
            position: "relative",
            zIndex: 1000,
            margin: 0,
          }}
        >
          <div
            className="col col-md-6 col-lg-4"
            style={{
              color: "white",
              paddingInline: "30px",
              paddingTop: "20px",
            }}
          >
            {userEmail != undefined && (
              <div
                style={{
                  padding: "20px",
                  marginBottom: "30px",
                  borderRadius: "30px",
                  // borderTopLeftRadius: "20px",
                  // borderBottomLeftRadius: "20px",
                  // borderTopRightRadius: "60px",
                  // borderBottomRightRadius: "60px",
                  border: "2px solid #000000",
                  background: "rgb(0,0,0)",
                  background:
                    "linear-gradient(90deg, rgba(52, 24, 0, 0.935) 0%, rgba(0, 0, 0, 0) 100%)",
                }}
              >
                {userType != "user" && (
                  <b>
                    {userType} {userOrg != undefined ? " | " + userOrg : ""}
                  </b>
                )}

                {userAnonymous == "true" ? (
                  <h3
                    style={{
                      fontSize: "25px",
                      textShadow: "#1c1c1c 1px 2px 0px",
                    }}
                  >
                    Welcome,
                    <br />
                    Bleeper#{userId.slice(-6)}
                  </h3>
                ) : (
                  <h3
                    style={{
                      fontSize: "25px",
                      textShadow: "#1c1c1c 1px 2px 0px",
                    }}
                  >
                    Welcome,
                    <br />
                    {userName}
                  </h3>
                )}
              </div>
            )}

            {userId == null && (
              <p
                style={{
                  fontSize: "30px",
                  textShadow: "#1c1c1c 1px 2px 0px",
                }}
              >
                Welcome Bleeper!
              </p>
            )}
            <b
              style={{
                fontSize: "30px",
                lineHeight: "20px",
                textShadow: "#1c1c1c 1px 2px 0px",
              }}
            >
              Suspect a counterfeit?
            </b>
            <div
              style={{
                paddingTop: "20px",
              }}
              className="align-items-center"
            >
              <div
                style={{
                  marginBottom: "20px",
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                  paddingBlock: "10px",
                  marginRight: "20px",
                  width: "100%",
                }}
              >
                {(userId == null || userType == "user") && (
                  <button
                    type="button"
                    className="btn btn-light btn-lg shimmer bleep-btn"
                    onClick={handleShowReportFormModal}
                  >
                    <span style={{ marginRight: "10px" }}>
                      <i className="fa-solid fa-bolt-lightning"></i>
                    </span>
                    <b>REPORT NOW</b>
                  </button>
                )}
              </div>

              {/* <a
                  onClick={() => {
                    trackEvent("Downloaded v1.2.2 Beta APK");
                  }}
                  href="/downloads/bleep v1.2.2 Beta.apk"
                  download={true}
                >
                  <div
                    className="badge rounded-pill text-bg-dark"
                    style={{
                      marginBottom: "20px",
                      padding: "10px",
                    }}
                  >
                    <span style={{ marginRight: "5px" }}>
                      <i className="fas fa-download"></i>
                    </span>
                    Download the Bleep App (Android)
                    {(userType === "superadmin" || userType === "admin") &&
                      " - " + downloadTrackerCount + " downloads"}
                  </div>
                </a> */}

              {/* Playstore button */}
              <a
                onClick={() => {
                  trackEvent("Clicked Play Store Link");
                }}
                href="https://play.google.com/store/apps/details?id=com.acha.BLEEP"
              >
                <img
                  src="/images/getitongoogleplaybadge.png"
                  style={{ height: "50px", marginRight: "5px" }}
                />
              </a>
              {/* <img
                src="/images/appstore_btn.svg"
                style={{ height: "50px" }}
              /> */}

              <div
                style={{
                  width: 400,
                  height: 400,
                  top: 0,
                  left: 0,
                  position: "absolute",
                  pointerEvents: "none",
                }}
              >
                <div
                  ref={lightninganimationcontainer}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                ></div>
              </div>
            </div>
          </div>
          {/* Sliding image carousel */}
          <div
            className="col-md-6 col-lg-8 d-none d-md-block d-xl-block"
            style={{
              margin: "0px",
              paddingRight: "30px",
              paddingBottom: "20px",
            }}
            align="right"
          >
            <Splide
              options={{
                perPage: 1,
                rewind: true,
                autoplay: true,
                interval: 4000,
                arrows: false,
              }}
            >
              <SplideSlide>
                <div
                  style={{
                    position: "relative",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    background:
                      "linear-gradient(0deg, rgba(255, 64, 0, 0.914) 0%, rgb(255, 153, 1) 100%)",
                    borderRadius: "20px",
                  }}
                >
                  <img
                    src="/images/slide1.png"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "400px",
                      borderRadius: "20px",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      textAlign: "center",
                      width: "100%",
                      padding: "10px",
                      paddingBottom: "20px",
                      color: "#fff",
                      lineHeight: "20px",
                      backgroundColor: "#00000070",
                      borderRadius: "20px",
                    }}
                    className="caption"
                  >
                    <b>REPORT</b>
                    <br />
                    <p>Empowering consumers to report counterfeit products.</p>
                  </div>
                </div>
              </SplideSlide>

              <SplideSlide>
                <div
                  style={{
                    position: "relative",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    background:
                      "linear-gradient(0deg, #273507 0%, rgb(255, 153, 1) 100%)",
                    borderRadius: "20px",
                  }}
                >
                  <img
                    src="/images/slide2.png"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "400px",
                      borderRadius: "20px",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      textAlign: "center",
                      width: "100%",
                      padding: "10px",
                      paddingBottom: "20px",
                      color: "#fff",
                      lineHeight: "20px",
                      backgroundColor: "#00000070",
                      borderRadius: "20px",
                    }}
                    className="caption"
                  >
                    <b>VERIFY</b>
                    <br />
                    <p>Creating Awareness about Counterfeits</p>
                  </div>
                </div>
              </SplideSlide>

              <SplideSlide>
                <div
                  style={{
                    position: "relative",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    background:
                      "linear-gradient(0deg, #500a0a 0%, rgb(255, 153, 1) 100%)",
                    borderRadius: "20px",
                  }}
                >
                  <img
                    src="/images/slide3.png"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "400px",
                      borderRadius: "20px",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      textAlign: "center",
                      width: "100%",
                      padding: "10px",
                      paddingBottom: "20px",
                      color: "#fff",
                      lineHeight: "20px",
                      backgroundColor: "#00000070",
                      borderRadius: "20px",
                    }}
                    className="caption"
                  >
                    <b>TAKE ACTION</b>
                    <br />
                    <p>
                      Increase responsiveness & accountability by brands and
                      enforcement agencies.
                    </p>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
      </div>

      <div className="row m-0">
        <div
          className="col"
          style={{
            marginTop: "-180px",
            paddingTop: "10px",
            paddingInline: "0px",
            background:
              "linear-gradient(0deg, #150102 95%, rgba(255, 255, 255, 0)",
            zIndex: 1000,
          }}
        >
          <div style={{ overflow: "hidden" }}>
            <InfiniteScroll
              dataLength={posts.length}
              next={fetchPosts}
              hasMore={hasMore}
              loader={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "50px",
                  }}
                >
                  <div
                    ref={bleeploadinganimation}
                    style={{
                      width: 200,
                      height: 200,
                    }}
                  ></div>
                </div>
              }
              endMessage={
                <p style={{ textAlign: "center", color: "white" }}>
                  Looks like you've reached the beginning!{" "}
                  <i className="fas fa-glass-cheers"></i>
                </p>
              }
              style={{ overflow: "unset" }}
            >
              <div
                className="row row-cols-lg-4 row-cols-md-3 row-cols-sm-1 row-cols-1"
                style={{
                  margin: 0,
                  padding: 0,
                  paddingTop: "10px",
                }}
              >
                {posts &&
                  posts.map((post) => (
                    <Post
                      key={post._id}
                      post={post}
                      showEmailAuthModal={handleShowEmailAuthModal}
                    />
                  ))}
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "fixed",
          bottom: 10,
          right: 20,
          zIndex: 1000,
        }}
      >
        {/* Report Button */}
        {showFloatingReportButton && (userId == null || userType == "user") && (
          <div
            style={{
              paddingBottom: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={handleShowReportFormModal}
            >
              <div className="btn-floating-report">
                <i
                  className="fa-solid fa-bolt-lightning"
                  style={{
                    color: "white",
                    fontSize: 30,
                  }}
                ></i>
              </div>
              <div
                style={{
                  backgroundColor: "#292929",
                  paddingInline: 10,
                  paddingBlock: 5,
                  fontSize: 10,
                  whiteSpace: "nowrap",
                  color: "white",
                  borderRadius: 20,
                  marginTop: -10,
                  zIndex: 1000,
                }}
              >
                <span>Report</span>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            paddingBottom: "20px",
          }}
        >
          {/* Cypheme Button */}
          <Link
            to="/verify"
            className="text-decoration-none"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="btn-floating-verify">
                <img
                  src="/images/verify.svg"
                  style={{ height: "50px", filter: "invert(1)" }}
                />
              </div>
              <div
                style={{
                  backgroundColor: "#292929",
                  paddingInline: 10,
                  paddingBlock: 5,
                  fontSize: 10,
                  whiteSpace: "nowrap",
                  color: "white",
                  borderRadius: 20,
                  marginTop: -10,
                  zIndex: 1000,
                }}
              >
                <span>Verify product</span>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <ReportForm
        domain={domain}
        show={showReportFormModal}
        handleClose={handleCloseReportFormModal}
        handleFormSubmit={handleReportFormSubmit}
        handleSaveAndShowEmailAuthModal={handleSaveAndShowEmailAuthModal}
      />
      <EmailAuth
        domain={domain}
        show={showEmailAuthModal}
        handleClose={handleCloseEmailAuthModal}
        handleAuthComplete={handleAuthComplete}
      />
    </>
  );
};

export default Home;
